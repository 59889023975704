import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  notification
} from 'ant-design-vue'
import {
  ACCESS_TOKEN,
  TICKET
} from "@/store/mutation-types"
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
router.beforeEach((to, from, next) => {

  if (to.meta.title) document.title = `仟仕云企业培训学习管理平台-${to.meta.title}`
  let token = Vue.ls.get(ACCESS_TOKEN)
  let ticket = Vue.ls.get(TICKET)

  if (!token && to.name === 'home') {
    console.log(location.href, '我是url')
    next({
      name: 'login'
    })
  }else if(to.name === 'account'){
    next()
  } else if (!token && to.name !== 'login') {
    // 未登录且要跳转的页面不是登录页
    notification.error({
      message: '温馨提示',
      description: '您还未登录, 请先登录!'
    });
    next({
      name: 'login'
    })
  } else if (!token && to.name === 'login') {
    // 未登陆且要跳转的页面是登录页
    next()
  } else if (!ticket && !token && to.name === 'login') {
    // 未登陆且要跳转的页面是登录页
    next()
  } else if (token && to.name === 'login') {
    // 已登录且要跳转的页面是登录页
    notification.info({
      message: '温馨提示',
      description: '已经登录, 自动为您跳转到首页'
    });
    next({
      name: 'home'
    })
  } else {
    next()
  }
})

// else if(to.name === 'exam') {
// notification.info({
//   message: '温馨提示',
//   description: '您当前正在考试,请先提交试卷'
// });
// }


export default router
