export default [
  {
    path: '/news',
    meta: {
      title: '消息',
      isMenu: true,
      key: 'news'
    },
    component: () => import('@/layout'),
    children: [
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news'),
        meta: {
          title: '消息中心',
          module: 'news',
          moduleText: '消息中心',
          hideSide: false,
          icon: 'mail'
        }
      },
      {
        path: '/news/report',
        name: 'report',
        component: () => import('@/views/news/report'),
        meta: {
          title: '我的报告',
          module: 'news',
          moduleText: '消息中心',
          hideSide: false,
          icon: 'container'
        }
      },
      {
        path: '/news/train',
        name: 'train',
        component: () => import('@/views/news/train'),
        meta: {
          title: '训练管理',
          module: 'news',
          moduleText: '消息中心',
          hideSide: false,
          icon: 'project'
        }
      },
      {
        path: '/news/details',
        name: 'newsDetails',
        component: () => import('@/views/news/details'),
        meta: {
          title: '消息详情',
          parents: 'news',
          module: 'news',
          moduleText: '消息中心',
          hideSide: true
        }
      },
      {
        path: '/news/train-details',
        name: 'trainDetails',
        component: () => import('@/views/news/train-details'),
        meta: {
          title: '查看详情',
          parents: 'train',
          module: 'news',
          moduleText: '消息中心',
          hideSide: true
        }
      }
    ]
  }
]