export default [{
  path: '/',
  redirect: '/home',
  component: () => import('@/layout'),
  meta: {
    title: '首页',
    isMenu: true,
    key: 'home',
    meta: {
      requiresAuth: true
    }
  },
  children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/home'),
      meta: {
        title: '同城',
        module: 'home',
        moduleText: '首页',
        hideSide: true
      }
    },
    {
      path: '/home/base',
      name: 'base',
      component: () => import('@/views/home/base'),
      meta: {
        title: '总部',
        module: 'home',
        moduleText: '首页',
        hideSide: true
      }
    },
    {
      path: '/home/credit',
      name: 'credit',
      component: () => import('@/views/home/credit-list'),
      meta: {
        title: '学分排行榜',
        module: 'home',
        moduleText: '首页',
        hideSide: false,
        icon: 'ordered-list'
      }
    }
  ]
}]