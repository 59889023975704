import HttpRequest from '@/utils/axios'

const get = ({ url, data, headers }) => {
  return new Promise((resolve, reject) => {
    HttpRequest.get(url, {
      params: data,
      headers
    }).then(res => {
      resolve(res)
    }).catch(err => {
      // handleError(err)
      reject(err)
    })
  })
}

const post = ({ url, data, headers }) => {
  return new Promise((resolve, reject) => {
    HttpRequest.post(url, data, {
      headers
    }).then(res => {
      resolve(res)
    }).catch(err => {
      // handleError(err)
      reject(err)
    })
  })
}

const put = ({ url, data, headers }) => {
  return new Promise((resolve, reject) => {
    HttpRequest.put(url, data, {
      headers
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const del = ({ url, data, headers }) => {
  return new Promise((resolve, reject)=> {
    HttpRequest.delete(url, data, {
      headers
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const request = ({ method, url, data }) => {
  if (method.toLowerCase() === 'post') {
    return post({
      url,
      data
    })
  } else if (method.toLowerCase() === 'put') {
    return put({
      url,
      data
    })
  } else if (method.toLowerCase() === 'delete') {
    return del({
      url,
      data
    })
  } else {
    return get({
      url,
      data
    })
  }
}

export default {
  request,
  get,
  post,
  put,
  del
}
