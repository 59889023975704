export default [
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account'),
      meta: {
        title: '忘记密码',
        isMenu: false
      }
    },{
      path: '/tips',
      name: 'tips',
      component: () => import('@/views/tips'),
      meta: {
        title: '暂无信息',
        isMenu: false
      }
    }
  ]