import axios from 'axios'
import Vue from 'vue'
import {
  notification
} from 'ant-design-vue'
import {
  ACCESS_TOKEN
} from "@/store/mutation-types"
import {
  apiBaseUrl
} from '@/config'
import store from '@/store'

const server = axios.create({
  baseURL: apiBaseUrl,
  timeout: 60000 // 请求超时时间
})

let isShow = false

server.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  // store.state.loadding = true; //在请求发出之前进行一些操作
  if (token) {
    config.headers['X-Access-Token'] = token
  }
  return config
}, error => {
  return Promise.reject(error)
})

server.interceptors.response.use((response) => {
  // store.state.loadding = false;//在这里对返回的数据进行处理
  if (response.data.code === 200 || response.data.success) {
    return response.data
  }

  return Promise.reject(response.data.message)
}, error => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    switch (error.response.status) {
      case 500:
        if (isShow) return false
        isShow = true
        console.log("------error.response------", error.response)
        // eslint-disable-next-line no-empty
        var type = error.response.request.responseType;
        if (type === 'blob') {
          blobToJson(data);
          break;
        }
        if (token && data.message.includes("Token失效")) {
          /*Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()*/
          notification.error({
            message: '温馨提示',
            description: '登录信息已过期,将自动返回登录页!'
          });
          setTimeout(() => {
            store.dispatch('user/Logout').then(() => {
              Vue.ls.remove(ACCESS_TOKEN)
              try {
                let path = window.document.location.pathname
                console.log("location pathname -> " + path)
                if (path != "/" && path.indexOf('/user/login') == -1) {
                  window.location.reload()
                }
              } catch (e) {
                window.location.reload()
              }
            })
          }, 2000);
        } else {
          notification.error({
            message: '系统提示',
            description: data.message,
            duration: 4
          })
          isShow = false
        }
        break
      default:
        notification.error({
          message: '系统提示',
          description: '服务器开小差. 请稍后再试!',
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
})

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  let fileReader = new FileReader();
  let token = Vue.ls.get(ACCESS_TOKEN);
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result); // 说明是普通对象数据，后台转换失败
      console.log("jsonData", jsonData)
      if (jsonData.status === 500) {
        console.log("token----------》", token)
        if (token && jsonData.message.includes("Token失效")) {
          notification.error({
            message: '温馨提示',
            description: '登录信息已过期,请重新登录!'
          });
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err", err)
    }
  };
  fileReader.readAsText(data)
}

export default server