export default [{
  path: '/learn',
  meta: {
    title: '学习',
    isMenu: true,
    key: 'learn'
  },
  component: () => import('@/layout'),
  children: [{
      path: '/learn',
      name: 'learn',
      component: () => import('@/views/learn'),
      meta: {
        title: '学习任务',
        module: 'learn',
        moduleText: '学习',
        hideSide: true
      }
    },
    {
      path: '/learn/video-details',
      name: 'videoDetails',
      component: () => import('@/views/learn/video-details'),
      meta: {
        title: '录播课程',
        module: 'learn',
        moduleText: '学习',
        hideSide: true
      }
    },
    // {
    //   path: '/learn/survey',
    //   name: 'survey',
    //   component: () => import('@/views/learn/survey'),
    //   meta: {
    //     title: '调查问卷',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: true
    //   }
    // },
    {
      path: '/learn/exam',
      name: 'exam',
      component: () => import('@/views/learn/exam'),
      meta: {
        title: '考试',
        module: 'learn',
        moduleText: '学习',
        hideSide: true
      }
    },
    {
      path: '/learn/exam/wrong-problem',
      name: 'wrong-problem',
      component: () => import('@/views/learn/exam/wrong-problem'),
      meta: {
        title: '考试',
        module: 'learn',
        moduleText: '学习',
        hideSide: true
      }
    },
    // {
    //   path: '/learn/recommend',
    //   name: 'recommend',
    //   component: () => import('@/views/learn/recommend'),
    //   meta: {
    //     title: '每日推荐',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: false,
    //     icon: 'heart'
    //   }
    // },
    // {
    //   path: '/learn/classroom',
    //   name: 'classroom',
    //   component: () => import('@/views/learn/classroom'),
    //   meta: {
    //     title: '微课堂',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: false,
    //     icon: 'play-circle'
    //   }
    // },
    // {
    //   path: '/learn/clound-talk',
    //   name: 'cloundTalk',
    //   component: () => import('@/views/learn/clound-talk'),
    //   meta: {
    //     title: '逸Talk',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: false,
    //     icon: 'video-camera'
    //   }
    // },
    // {
    //   path: '/learn/external',
    //   name: 'external',
    //   component: () => import('@/views/learn/external'),
    //   meta: {
    //     title: '外部课程',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: false,
    //     icon: 'play-square'
    //   }
    // },
    // {
    //   path: '/learn/offline',
    //   name: 'offline',
    //   component: () => import('@/views/learn/offline'),
    //   meta: {
    //     title: '线下课程',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: false,
    //     icon: 'read'
    //   }
    // },
    // {
    //   path: '/learn/live',
    //   name: 'live',
    //   component: () => import('@/views/learn/live-player'),
    //   meta: {
    //     title: '直播播放器',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: true
    //   }
    // },
    {
      path: '/learn/garden-details',
      name: 'gardenDetails',
      component: () => import('@/views/learn/garden-details'),
      meta: {
        title: '学习园地',
        module: 'learn',
        moduleText: '学习',
        hideSide: true
      }
    }
      // {
    //   path: '/learn/banner-details',
    //   name: 'bannerDetails',
    //   component: () => import('@/views/learn/banner-details'),
    //   meta: {
    //     title: '轮播图课程详情',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: true
    //   }
    // },
    // {
    //   path: '/learn/offine-details',
    //   name: 'offineDetails',
    //   component: () => import('@/views/learn/offine-details'),
    //   meta: {
    //     title: '线下课程详情',
    //     module: 'learn',
    //     moduleText: '学习',
    //     hideSide: true
    //   }
    // }
  ]
}]