import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadding: false
  },
  modules: {
    user
  },
  mutations: {
    showloadding(state, load) {
      state.loadding = load
    }
  },
  // 可以用store.dispatch来调用
  actions: {
    setloadding(context,load){
      context.commit("showloadding",load);
    }
  },
  getters: {
    isloading:(state)=>{
      return state.loadding
    }
  }
})
