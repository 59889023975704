import Vue from 'vue'
import Storage from 'vue-ls'
import antDesignUI from 'ant-design-vue'
import moment from 'moment'
import 'ant-design-vue/dist/antd.css'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import '@/style/ant-design.less' // 配置主題顏色

Vue.use(Storage)
Vue.use(antDesignUI)
Vue.prototype.$locale = locale
Vue.prototype.$moment = moment