export const ACCESS_TOKEN = 'Access-Token'
export const USER_NAME = 'USER_NAME'
export const USER_AVATAR = 'USER_AVATAR'
export const POINT = 'POINT'
export const SCORE = 'SCORE'
export const USERID = 'USERID'
export const PHONE = 'PHONE'
export const USER_ID = 'USER_ID'
export const STUDY_TIME = 'STUDY_TIME'
export const DEPARTMENT = 'DEPARTMENT'
export const POSITION = 'POSITION'
export const WORK_NO = 'WORK_NO'
export const TICKET = 'TICKET'
export const COUNT = 'COUNT'
export const NEWCOUNT = 'NEWCOUNT'