// const isDev = process.env.NODE_ENV === 'development' // 开发环境
//测试http://202.170.143.34:8193
//正式http://8.134.88.207:8080

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

const staticBaseUrl = process.env.VUE_APP_API_BASE_URL + '/api'

const staticDomainURL = staticBaseUrl + '/sys/common/static'

const pdfDomainURL = staticBaseUrl + '/generic/web/viewer.html?file='

const uploadURL = staticBaseUrl + '/sys/common/upload'
export {
  apiBaseUrl,
  staticDomainURL,
  pdfDomainURL,
  uploadURL
}
// 

// http://8.134.88.207:8080