import home from './modules/home'
import login from './modules/login'
import account from './modules/account'
import learn from './modules/learn'
// import community from './modules/community'
import news from './modules/news'
import talents from './modules/talents'
import user from './modules/user'

export default [
  ...login,
  ...account,
  ...home,
  ...learn,
  // ...community,
  ...talents,
  ...news,
  
  ...user
]