export default [
  {
    path: '/talents',
    meta: {
      title: '监管',
      isMenu: true,
      key: 'talents'
    },
    component: () => import('@/layout'),
    children: [
      {
        path: '/talents',
        name: 'talents',
        component: () => import('@/views/talents'),
        meta: {
          title: '员工学习数据',
          module: 'talents',
          moduleText: '监管',
          hideSide: false,
          icon: 'team'
        }
      },
      // {
      //   path: '/talents/details',
      //   name: 'talentsDetails',
      //   component: () => import('@/views/talents/details'),
      //   meta: {
      //     title: '人才储备详情',
      //     parents: 'talents',
      //     module: 'talents',
      //     moduleText: '人才储备',
      //     hideSide: true
      //   }
      // },
      // {
      //   path: '/talents/rate',
      //   name: 'rate',
      //   component: () => import('@/views/talents/rate'),
      //   meta: {
      //     title: '人才评级',
      //     module: 'talents',
      //     moduleText: '人才储备',
      //     hideSide: false,
      //     icon: 'user-add'
      //   }
      // },
      // {
      //   path: '/talents/performance',
      //   name: 'performance',
      //   component: () => import('@/views/talents/performance'),
      //   meta: {
      //     title: '绩效评级',
      //     parents: 'rate',
      //     module: 'talents',
      //     moduleText: '人才储备',
      //     hideSide: true
      //   }
      // },
      // {
      //   path: '/talents/ability',
      //   name: 'ability',
      //   component: () => import('@/views/talents/ability'),
      //   meta: {
      //     title: '能力评分',
      //     parents: 'rate',
      //     module: 'talents',
      //     moduleText: '人才储备',
      //     hideSide: true
      //   }
      // },
      // {
      //   path: '/talents/task',
      //   name: 'rateTask',
      //   component: () => import('@/views/talents/rate-task'),
      //   meta: {
      //     title: '任务评价',
      //     parents: 'rate',
      //     module: 'talents',
      //     moduleText: '人才储备',
      //     hideSide: true
      //   }
      // }
    ]
  }
]