<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default {
  data() {
    return {
      zh_CN,
    }
  }
}
</script>

<style lang="scss">
#app {
   background: #F5F5F5;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #5db4ff;
}
::-webkit-scrollbar-track {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background: #5db4ff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #5db4ff;
}
::-webkit-scrollbar-corner {
  background: #5db4ff;
}

::v-deep .ant-spin-nested-loading > div > .ant-spin{
  max-height: 500px;
}
</style>
