import Vue from 'vue'
import {
  // login,
  loginNew,
  passwordLogin,
  logout,
  getToken,
  getUserData
} from '@/api/user.js'
import {
  ACCESS_TOKEN,
  USER_NAME,
  PHONE,
  USER_AVATAR,
  POINT,
  SCORE,
  USER_ID,
  STUDY_TIME,
  DEPARTMENT,
  POSITION,
  WORK_NO,
  // TICKET
} from '@/store/mutation-types.js'
import {
  notification
} from 'ant-design-vue'
import Stomp from 'stompjs'
const MQTT_USERNAME = 'admin' // mqtt连接用户名
const MQTT_PASSWORD = '123456' // mqtt连接密码
const MQTT_SERVICE = 'ws://124.232.163.68:15674/ws' // mqtt连接服务地址

export default {
  namespaced: true,
  state: {
    token: '',
    username: '',
    workNo: '',
    userId: '',
    avatar: '', // 头像
    info: '', //  是否有用户信息
    point: '',
    score: '',
    phone: '',
    studyTime: '',
    department: '',
    position: '',
    ticket: '',
    userIdentity:''
  },
  mutations: {
    SET_IDENTI(state,userIdentity){
      state.userIdentity = userIdentity
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER_NAME(state, name) {
      state.username = name
    },
    SET_WORK_NO(state, number) {
      state.workNo = number
    },
    SET_AVATAR(state, avatar) {
      state.avatar = avatar
    },
    SET_INFO(state, status) {
      state.info = status
    },
    SET_POINT(state, point) {
      state.point = point
    },
    SET_SCORE(state, score) {
      state.score = score
    },
    SET_PHONE(state, phone) {
      state.phone = phone
    },
    SET_USER_ID(state, id) {
      state.userId = id
    },
    SET_STUDY_TIME(state, study) {
      state.studyTime = study
    },
    SET_DEPARTMENT(state, str) {
      state.department = str
    },
    SET_POSITION(state, str) {
      state.position = str
    },

    // SET_TICKET(state, str) {
    //   state.ticket = str
    // }
  },
  getters: {
    getUserIdentity: state => {
        return state.userIdentity
    },
  },
  actions: {
    // 通过手机号登录
    mobileLogin({
      commit
    }, {
      mobile,
      code
    }) {
      return new Promise((resolve, reject) => {
        loginNew({
          telephone: mobile,
          captcha: code
        }).then(res => {
          console.log(res, '保存的数据')
          Vue.ls.set(ACCESS_TOKEN, res.result.token)
          Vue.ls.set(USER_NAME, res.result.user.realname)
          Vue.ls.set(WORK_NO, res.result.user.workNo)
          // Vue.ls.set(USER_AVATAR, res.result.user.avatar)
          Vue.ls.set(POINT, res.result.user.point)
          Vue.ls.set(SCORE, res.result.user.score)
          Vue.ls.set(PHONE, mobile)
          Vue.ls.set(USER_ID, res.result.user.id)
          // Vue.ls.set(STUDY_TIME, res.result.user.studyDuration)
          // Vue.ls.set(DEPARTMENT, res.result.user.departList.join())
          
          localStorage.setItem('userIdentity',res.result.user.userIdentity)
          commit('SET_IDENTI', res.result.user.userIdentity)
          commit('SET_TOKEN', res.result.token)
          commit('SET_USER_NAME', res.result.user.realname)
          commit('SET_WORK_NO', res.result.user.workNo)
          // commit('SET_AVATAR', res.result.user.avatar)
          commit('SET_POINT', res.result.user.point)
          commit('SET_SCORE', res.result.user.score)
          commit('SET_PHONE', mobile)
          commit('SET_USER_ID', res.result.user.id)
          // commit('SET_STUDY_TIME', res.result.user.studyDuration)
          // commit('SET_DEPARTMENT', res.result.user.departList.join())
          if (res.result.user.positionList !== null) {
            Vue.ls.set(POSITION, res.result.user.positionList.join())
            commit('SET_POSITION', res.result.user.positionList.join())
          }
          if (res.result.user.departList !== null) {
            Vue.ls.set(DEPARTMENT, res.result.user.departList.join())
            commit('SET_DEPARTMENT', res.result.user.departList.join())
          }
          if (res.result.user.studyDuration !== null) {
            Vue.ls.set(STUDY_TIME, res.result.user.studyDuration)
            commit('SET_STUDY_TIME', res.result.user.studyDuration)
          }
          if (res.result.user.avatar !== null) {
            Vue.ls.set(USER_AVATAR, res.result.user.avatar)
            commit('SET_AVATAR', res.result.user.avatar)
          }
          return resolve(res)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    getTokenLogin({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        getToken({ code: params.code, appId: params.appid }).then(data => {
          Vue.ls.set(ACCESS_TOKEN, data.result.token)
          commit('SET_TOKEN', data.result.token)
          getUserData().then(res => {
            // Vue.ls.set(USER_NAME, res.result.realname)
            // Vue.ls.set(WORK_NO, res.result.workNo)
            // Vue.ls.set(USER_AVATAR, res.result.avatar)
            Vue.ls.set(POINT, res.result.point)
            Vue.ls.set(SCORE, res.result.score)
            Vue.ls.set(USER_ID, res.result.id)
            // Vue.ls.set(STUDY_TIME, res.result.studyDuration)
            // Vue.ls.set(DEPARTMENT, res.result.departList.join())
            localStorage.setItem('userIdentity',res.result.userIdentity)
            // commit('SET_USER_NAME', res.result.realname)
            commit('SET_WORK_NO', res.result.workNo)
            // commit('SET_AVATAR', res.result.avatar)
            commit('SET_POINT', res.result.point)
            commit('SET_SCORE', res.result.score)
            commit('SET_USER_ID', res.result.id)
            // commit('SET_STUDY_TIME', res.result.studyDuration)
            // commit('SET_DEPARTMENT', res.result.departList.join())
            if (res.result.realname !== null) {
              Vue.ls.set(USER_NAME, res.result.realname)
              commit('SET_USER_NAME', res.result.realname)
            }
            if (res.result.workNo !== null) {
              Vue.ls.set(WORK_NO, res.result.workNo)
              commit('SET_WORK_NO', res.result.workNo)
            }
            if (res.result.positionList !== null) {
              Vue.ls.set(POSITION, res.result.positionList.join())
              commit('SET_POSITION', res.result.positionList.join())
            }
            if (res.result.departList !== null) {
              Vue.ls.set(DEPARTMENT, res.result.departList.join())
              commit('SET_DEPARTMENT', res.result.departList.join())
            }
            if (res.result.studyDuration !== null) {
              Vue.ls.set(STUDY_TIME, res.result.studyDuration)
              commit('SET_STUDY_TIME', res.result.studyDuration)
            }
            if (res.result.avatar !== null) {
              Vue.ls.set(USER_AVATAR, res.result.avatar)
              commit('SET_AVATAR', res.result.avatar)
            }
            return resolve(res)
          })
        }).catch(err => {
          return reject(err)
        })
      })
    },
    connect() {
      this.client = Stomp.client(MQTT_SERVICE)
      this.client.connect(MQTT_USERNAME, MQTT_PASSWORD, this.onConnected, this.onFailed)
    },
    onConnected(frame) {
      console.log('connected:' + frame)
      var exchange = '/exchange/login'
      this.client.subscribe(exchange, this.responseCallback, this.onFailed)
      console.log(frame)
    },
    onFailed(frame) {
      console.log('Failed' + frame)
    },
    // 登出
    Logout({
      commit
    }) {
      return new Promise((resolve) => {
        commit('SET_IDENTI', '')
        commit('SET_TOKEN', '')
        commit('SET_USER_NAME', '')
        commit('SET_AVATAR', '')
        commit('SET_USER_ID', '')
        localStorage.removeItem('userIdentity')
        logout().then((res) => {
          Vue.ls.remove(ACCESS_TOKEN)
          // Vue.ls.remove(TICKET)
          notification.success({
            message: '系统提示',
            description: '退出登录成功',
            duration: 4
          })
          // if (process.env.VUE_APP_SSO == 'true') {
          //   let sevice = 'http://' + window.location.host + '/'
          //   let serviceUrl = encodeURIComponent(sevice)
          //   window.location.href = process.env.VUE_APP_CAS_BASE_URL + '/logout?service=' + serviceUrl
          // }
          resolve(res)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    // 通过账号密码登录
    accountLogin({
      commit
    },{
      mobile,
      password
    }) {
      return new Promise((resolve, reject) => {
        passwordLogin({
          telephone: mobile,
          password: password
        }).then(res => {
          console.log(res, '保存的数据')
          Vue.ls.set(ACCESS_TOKEN, res.result.token)
          Vue.ls.set(USER_NAME, res.result.user.realname)
          Vue.ls.set(WORK_NO, res.result.user.workNo)
          // Vue.ls.set(USER_AVATAR, res.result.user.avatar)
          Vue.ls.set(POINT, res.result.user.point)
          Vue.ls.set(SCORE, res.result.user.score)
          Vue.ls.set(PHONE, mobile)
          Vue.ls.set(USER_ID, res.result.user.id)
          // Vue.ls.set(STUDY_TIME, res.result.user.studyDuration)
          // Vue.ls.set(DEPARTMENT, res.result.user.departList.join())
          localStorage.setItem('userIdentity',res.result.user.userIdentity)
          commit('SET_IDENTI', res.result.user.userIdentity)
          commit('SET_TOKEN', res.result.token)
          commit('SET_USER_NAME', res.result.user.realname)
          commit('SET_WORK_NO', res.result.user.workNo)
          // commit('SET_AVATAR', res.result.user.avatar)
          commit('SET_POINT', res.result.user.point)
          commit('SET_SCORE', res.result.user.score)
          commit('SET_PHONE', mobile)
          commit('SET_USER_ID', res.result.user.id)
          // commit('SET_STUDY_TIME', res.result.user.studyDuration)
          // commit('SET_DEPARTMENT', res.result.user.departList.join())
          if (res.result.user.positionList !== null) {
            Vue.ls.set(POSITION, res.result.user.positionList.join())
            commit('SET_POSITION', res.result.user.positionList.join())
          }
          if (res.result.user.departList !== null) {
            Vue.ls.set(DEPARTMENT, res.result.user.departList.join())
            commit('SET_DEPARTMENT', res.result.user.departList.join())
          }
          if (res.result.user.studyDuration !== null) {
            Vue.ls.set(STUDY_TIME, res.result.user.studyDuration)
            commit('SET_STUDY_TIME', res.result.user.studyDuration)
          }
          if (res.result.user.avatar !== null) {
            Vue.ls.set(USER_AVATAR, res.result.user.avatar)
            commit('SET_AVATAR', res.result.user.avatar)
          }
          return resolve(res)
        }).catch(err => {
          return reject(err)
        })
      })
    }

  }
}