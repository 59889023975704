export default [{
  path: '/user',
  component: () => import('@/layout'),
  meta: {
    title: '个人中心',
    isMenu: true,
    key: 'user'
  },
  children: [{
      path: '/user',
      name: 'user',
      component: () => import('@/views/user'),
      meta: {
        title: '个人中心',
        module: 'user',
        moduleText: '个人中心',
        hideSide: false,
        icon: 'user'
      }
    },
    {
      path: '/user/certificate',
      name: 'certificate',
      component: () => import('@/views/user/certificate'),
      meta: {
        title: '我的证书',
        module: 'user',
        moduleText: '个人中心',
        hideSide: false,
        icon: 'book'
      }
    },
    {
      path: '/user/certificate/details',
      name: 'cerDetails',
      component: () => import('@/views/user/certificate/details'),
      meta: {
        title: '查看证书',
        parents: 'certificate',
        module: 'user',
        moduleText: '个人中心',
        hideSide: true
      }
    },
    // {
    //   path: '/user/apply',
    //   name: 'apply',
    //   component: () => import('@/views/user/apply'),
    //   meta: {
    //     title: '讲师申请',
    //     module: 'user',
    //     moduleText: '个人中心',
    //     hideSide: false,
    //     icon: 'form'
    //   }
    // },
    // {
    //   path: '/user/share',
    //   name: 'share',
    //   component: () => import('@/views/user/share'),
    //   meta: {
    //     title: '我的分享',
    //     module: 'user',
    //     moduleText: '个人中心',
    //     hideSide: false,
    //     icon: 'share-alt'
    //   }
    // },
    {
      path: '/user/share/edit',
      name: 'shareEdit',
      component: () => import('@/views/user/share/edit'),
      meta: {
        title: '分享编辑',
        parents: 'share',
        module: 'user',
        moduleText: '个人中心',
        hideSide: true
      }
    },
    {
      path: '/user/share/details',
      name: 'shareDetails',
      component: () => import('@/views/user/share/details'),
      meta: {
        title: '分享详情',
        parents: 'share',
        module: 'user',
        moduleText: '个人中心',
        hideSide: true
      }
    },
    {
      path: '/user/credit',
      name: 'creditDetail',
      component: () => import('@/views/user/credit'),
      meta: {
        title: '学分明细',
        module: 'user',
        moduleText: '个人中心',
        hideSide: false,
        icon: 'pie-chart'
      }
    },
    // {
    //   path: '/user/history',
    //   name: 'history',
    //   component: () => import('@/views/user/history'),
    //   meta: {
    //     title: '观看历史',
    //     module: 'user',
    //     moduleText: '个人中心',
    //     hideSide: false,
    //     icon: 'history'
    //   }
    // },
    // {
    //   path: '/user/path',
    //   name: 'path',
    //   component: () => import('@/views/user/path'),
    //   meta: {
    //     title: '学习路径',
    //     module: 'user',
    //     moduleText: '个人中心',
    //     hideSide: false,
    //     icon: 'rise'
    //   }
    // },
    // {
    //   path: '/user/record',
    //   name: 'record',
    //   component: () => import('@/views/user/record'),
    //   meta: {
    //     title: '浏览记录',
    //     module: 'user',
    //     moduleText: '个人中心',
    //     hideSide: false,
    //     icon: 'snippets'
    //   }
    // }
  ]
}]